import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { DeathState } from './types';
import { deathService } from '@/services/death.service';
import { DeclarationStat } from '@/models/partners/birth/birth-model';
import { DeathUpdateDto, _DeathUpdateDto } from '@/dto/partners/death.dto';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";
import { DeathModel } from '@/models/partners/death/death-model';
import { partnerService } from '@/services/partner.service';


export const actions: ActionTree<DeathState, RootState> = {
  loadDeaths(context) {
    deathService
      .getInstance(this.getters.getUser.access_token)
      .loadDeaths()
      .then((response) => {
        if (response.data) {
          context.dispatch('setDeaths', response.data)
          context.dispatch('loadDeathStats') 
          context.commit('setDeathsHealthCenterName', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadFileUri(context, payload: string) {
    partnerService
      .getInstance(this.getters.getUser.access_token)
      .loadFileUri(payload)
      .then((response) => {
        if (response.data) {
          return response
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadDeathStats(context) {
    deathService
      .getInstance(this.getters.getUser.access_token)
      .loadDeathStats()
      .then((response) => {
        if (response.data) {
          context.dispatch('setDeathStat', response.data)
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  updateDeathStatus(context, payload: DeathUpdateDto){
    return deathService
      .getInstance(this.getters.getUser.access_token)
      .updateDeathStatus(payload)
      .then((response) => {
        if (response.apiError) {
          console.log('error');
          return false
        }else{
          context.dispatch('loadDeathStats') 
          const birth: _DeathUpdateDto = {
            death_id: payload.death_id,
            status: payload.status
          } 
          context.commit('_setDeath', birth)
          return true
        }
      });
  },

  setDeaths(context, payload: Array<DeathModel>) {
    context.commit('setDeaths', payload);
  },

  setDeath(context, payload: DeathModel | undefined) {
    context.commit('setDeath', payload);
  },

  setDeathStat(context, payload: DeclarationStat){
    context.commit('setDeathStat', payload);
  },

  setStatutFilter(context, payload: string | null){
    context.commit('setStatutFilter', payload);
  },

  setNameFilter(context, payload: string | null){
    context.commit('setNameFilter', payload);
  },

  setDayLeftFilter(context, payload: number | null){
    context.commit('setDayLeftFilter', payload);
  },

  setHealthCenterFilter(context, payload: string | null){
    context.commit('setHealthCenterFilter', payload);
  },
};
