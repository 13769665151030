import { HttpBaseService } from '@/services/httpBase.service';
import { partnerServiceApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';



export class agentService extends HttpBaseService {
  private static classInstance?: agentService;
  static apiBaseUrl = partnerServiceApiUrl;

  constructor(token: string) {
    super(token, agentService.apiBaseUrl);
  }

  public static getInstance(token: string): agentService {
    if (!this.classInstance) {
      this.classInstance = new agentService(token);
    }

    return this.classInstance;
  }

  public async loadAgents(): Promise<ApiResponse<[]>> {
    const apiResponse = new ApiResponse<[]>();
    return await this.instance
      .get('/agents/admin')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}